#top {

	.map{

		@include max_screen($break-point) {
			iframe{
				height: 300px;
			}
		}
		//SP
	}

}
