body {
	font-family: $font-jp;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	position: relative;
}

img{
	max-width: 100%;
}

img[src$=".svg"] {
	width: 100%;
	height: auto;
}


// *** --------------------
.contents{

	&__inner{
		width: 1000px;
		margin: 0 auto;

		@include max_screen($break-point) {
			width: 100%;
		}
		//SP
	}
}


// header --------------------
.header{

	&__inner{
		width: 1000px;
		margin: 0 auto;

		@include max_screen($break-point) {
			width: 100%;
		}
		//SP
	}
}

// footer --------------------
.footer{

	&__inner{
		width: 1000px;
		margin: 0 auto;

		@include max_screen($break-point) {
			width: 100%;
		}
		//SP
	}

	&__copyright{
		height: 43px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		line-height: 1;
		letter-spacing: .09em;
		text-indent: .09em;
		color: #999;
	}
}
